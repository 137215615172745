<template>
  <div class="search">
    <div class="header">
    </div>
    <div class="content">
      <div class="btn">
        <el-input placeholder="请输入内容" v-model="keyword" style="width: 600px;border-radius: 15px" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="flex">
        <div class="tubiao"><i class="el-icon-menu"></i><span>搜索类型</span></div>
        <el-tabs class="flex-1" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="党建引领" name="1"></el-tab-pane>
          <el-tab-pane label="政策服务" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="zhuti">
        <div class="news flex" v-for="(ar,index) in arr" :key="index">
          <div class="time flex">
            <div class="shang">{{formatFun(ar.time,'dd')}}日</div>
            <div class="xia">
              <div class="prohibit"></div>
              <div style="margin-left: 15px">
                <div>{{formatFun(ar.time,'MM')}}月</div>
                <div>{{formatFun(ar.time,'yyyy')}}年</div>
              </div>
            </div>
          </div>
          <div class="title flex-1">{{ar.title}}</div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import {formatFun} from '../../utils/index'
export default {
  name: "zchj",
  data(){
    return {
      formatFun:formatFun,
      keyword:'',
      page:{
        current:1,
        size:10,
        total:0
      },
      activeName:'0',
      arr:[{title:'财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告（2023年第69号）',peo:'工信局',time:'2021-12-15'},
        {title:'财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告（2023年第69号）',peo:'工信局',time:'2021-12-15'},
        {title:'财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告（2023年第69号）',peo:'工信局',time:'2021-12-15'},
        {title:'财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告（2023年第69号）',peo:'工信局',time:'2021-12-15'}],
    }
  },
  created() {
    this.keyword=this.$route.query.keyword
    this.loadlist()
  },
  methods:{
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        keyword:this.keyword
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zcfw.policyunscramblepage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    handleClick(){

    }
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 140px;
  background: url("../../assets/gxjy/gxjy.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .news{
    padding: 14px;
    border-bottom: 1px dashed #BFBFBF;
    .time{
      width: 140px;
      margin-left: 75px;
    }
    .shang{
      font-weight: bolder;
      margin-right: 8px;
      padding-top: 5px;
    }
    .xia{
      color: #BFBFBF;
      font-size: 12px;
      position: relative;
    }
    .prohibit{
      height: 0px;
      width: 24px;
      border-top: 2px solid #BFBFBF;
      transform: rotate(111deg);
      -o-transform: rotate(111deg);
      -moz-transform: rotate(11deg);
      -webkit-transform: rotate(111deg);
      position: relative;
      top: 14px;
      left: -7px;
      border-radius: 4px;
    }
  }
  .btn{
    text-align: center;
    margin-bottom: 40px;
  }
  .tubiao{
    padding-right: 20px;
    padding-top: 9px;
    height: 29px;
    border-bottom: 2px solid #E4E7ED;
     i{
       color: red;
       font-size: 20px;
     }
    span{
      padding: 0px 20px 0 10px;
      margin: 0px 10px 0 0;
      border-right: 2px solid #E4E7ED;
    }
  }
}
</style>